<template>
    <div style="height:45px">
        <div class="header_clone1" :style="`background:${bgc}`" v-if="bgcShow" @click="stepClone">
            <div><img src="../../assets/left.png" /></div>
            <div>{{ bgcTitle }}</div>
        </div>
        <div class="header_clone" :style="`background:${bgc}`" v-else @click="stepClone">
            <div><img src="../../assets/left.png" /></div>
            <div>{{ bgcTitle }}</div>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        bgcShow: {
            type: Boolean,
        },
        bgcTitle: {
            type: String,
        },
        bgc: {
            type: String,
        }
    },
    methods: {
        // 页面回退
        stepClone() {
            this.$router.go(-1)
        }
    }
}
</script>
<style>
.header_clone {
    width: 100%;
    display: flex;
    color: #fff;
    background-color: #fff;
    font-size: 30px;
    height: 92px;
    line-height: 92px;
    vertical-align: middle;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.header_clone1 {
    width: 100%;
    height: 92px;
    line-height: 92px;
    display: flex;
    justify-content: center;
    color: #000;
    background-color: #fff;
    font-size: 30px;
    vertical-align: middle;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.header_clone1 img {
    width: 42px;
    height: 35px;
    margin-right: 10px;
    position: absolute;
    left: 35px;
    top: 20px;
}

.header_clone img {
    width: 42px;
    height: 35px;
    margin-right: 10px;
    position: absolute;
    left: 35px;
    top: 20px;
}
</style>
